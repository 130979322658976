hr {
    border-top: 1px solid #DDDEDF;
    margin: 1rem 0;
}

.monSite-container {
    padding: 0 3%;
    min-width: 1166px;
}



.monSite-breadcrumb {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.monSite-stepsHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.monSite-stepsTitle h3,
.monSite-stepsDescription p {
    margin: 0 !important;
    margin-bottom: 1rem !important;
    text-align: center;
}

.monSite-stepsHeaderIcon {
    height: 158px;
    width: 158px;
    background-color: rgba(27, 186, 173, 0.08);
    border-radius: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem 0;
}
.monSite-alertIcon {
    height: 158px;
    width: 158px;
    background-color: rgba(249,170,0,.08);
    border-radius: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem 0;
}

.monSite-stepItem {
    border: 2px solid #EFEFEF;
    border-radius: 8px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
}

.monSite-stepsBody {
    margin: 1rem 0 !important;
    display: flex;
    flex-wrap: wrap;
}

.monSite-stepBody h3,
.monSite-stepBody p {
    margin: 0;
}

.monSite-stepNumber {
    height: 72px;
    width: 72px;
    border: 2px solid #EFEFEF;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 29px;
}

.monSite-stepButton {
    height: 54px;
    width: 54px;
    border-radius: 50%;
    background-color: rgba(0, 172, 210, .06);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.monSite-logoParams {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 1rem;
}

.monSite-logoParams input[type="color"] {
    -webkit-appearance: none;
    /* border: none; */
    overflow: hidden;
    border-radius: 8px !important;
    width: 45px;
    height: 45px;
    padding: 0 !important;
    margin: 0 1rem 0 0;
}

.monSite-logoParams input[type="color"]::-webkit-color-swatch-wrapper {
    padding: 0;
}

.monSite-logoParams input[type="color"]::-webkit-color-swatch {
    border: none;
}

.monSite-logoParams .sob-image-picker-direction-horizontal {
    justify-content: space-around;
}

.monSite-menuTableHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
    margin-bottom: 8px;
}

.monSite-menuTableHeader p {
    margin: 0 8px;
    font-weight: 600;
}

.monSite-dragRow {
    display: flex;
    flex-direction: row;
    margin-left: -15px;
    margin-right: -15px;
}

.monSite-subdomainInput {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.monSite-subdomain {}

.monSite-subdomainInput p {
    margin: 8px;
}

.monSite-slide {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 2px solid #EFEFEF;
    border-radius: 8px;
    padding: 12px 10px;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.monSite-slide__image {
    width: 323px;
    height: 164px;
    border-radius: 8px;
    border: 2px solid #EFEFEF;
    margin-right: 1rem;
    overflow: hidden;
    display: flex;
    justify-content: center;
}

.monSite-slide__body {
    margin: 0 10px;
}

.monSite-slide__icons {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.monSite-dashedButton {
    border: 2px dashed #00B1D8;
    border-radius: 8px;
    width: 100%;
    height: 119px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #00B1D8;
    font-size: 24px;
    margin: 1rem 0;
    cursor: pointer;
}

.monSite-modal {
    background-color: rgba(0, 0, 0, 0.31);
    z-index: 1;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    overflow: auto;
}

.monSite-modal__content {
    background-color: white;
    width: 60vw;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 8px;
    padding: 20px;
}

.monSite-modal__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.monSite-modal__footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 1rem 0;
}

.monSite-socialMediaInfos {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.monSite-socialMediaInfos__link {
    width: 70%;
    margin-left: 1rem;
}

.monSite-socialMediaInfos__icon {
    height: 55px;
    width: 70px;
    border-radius: 8px;
    background-color: #F0FAFD;
    display: flex;
    justify-content: center;
    align-items: center;
}

.monSite-sideMenuItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 2rem;
    overflow: hidden;
}

.monSite-progressionButton {
    padding: 10px;
    border-radius: 8px;
    font-size: .875rem;
    cursor: pointer;
    background-color: #f6f6f6;
    margin-bottom: 15px;
}

div[id^=font-picker] {
    display: block !important;
    padding: .55rem 0 !important;
    background: #fff !important;
    border: 1px solid #c6c6c6 !important;
    border-radius: 8px !important;
    font-size: .813rem !important;
    font-weight: 300 !important;
    width: 100% !important;
    box-shadow: none !important;
    height: 45px;
}

div[id^=font-picker] .dropdown-button {
    display: flex!important;
    align-items: baseline !important;
    justify-content: space-between!important;
    width: 100%!important;
    height: 35px!important;
    padding: 5px!important;
    background: #cbcbcb!important;
}

div[id^=font-picker] .dropdown-icon.finished::before {
    content: "";
    position: absolute;
    right: 12px;
    top: 20px;
    width: 7px !important;
    height: 7px !important;
    border-bottom: 1px solid #9d9d9d !important;
    border-right: 1px solid #9d9d9d !important;
    border-top: none !important;
    border-left: none !important;
    transform: rotate(45deg) !important
}

div[id^=font-picker] .dropdown-button {
    background-color: transparent !important;
}

div[id^=font-picker] ul {
    background-color: #fff !important;
}

div[id^=font-picker] ul li button:hover {
    background-color: #ebf9fc !important;
}

div[id^=font-picker] ul li button:focus {
    background-color: #00a4c9 !important;
    color: #fff !important;
}

/* .sob-row {
    margin: 1rem 0;
} */

textarea {
    resize: none;
}
.sob-row{
    flex-wrap: nowrap !important;
}
.alertModal {
    width: 30vw !important;
    text-align: center !important;
}
/* .sob-image-picker .rug-card{
    height: 0px!important;
    min-width: ''!important;
    width: ''!important;
    border-radius: 0px!important;
} */
.monSite-logoParams .sob-image-picker .rug-card {
    width: 200px!important;
    min-width: 200px!important;
}
/* .sob-modal-content .sob-image-picker .rug-card {
    height: 159px!important;
    min-width: 300px!important;
    width: 100%!important;
    border-radius: 8px!important;
} */

.sob-modal-content{
    width: unset !important;
}
.monSite-favIcon {
    position: relative;
}
.monSite-favIcon::before {
    content: "";
    position: absolute;
    top: 30%;
    left: 0;
    background-image: url(../images/icons/redArrow.svg);
    height: 50%;
    width: 50%;
    background-repeat: no-repeat;
}
.sob-accordion__button:before {
    top: 40%!important;
}

.sob-stepItem{
    height: 90%!important;
}

.monSite-login {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background: linear-gradient(to bottom, transparent 50%, #00a4c9 50%);
    
}

.monSite-login__button {
    width: 20%;
    min-width: 200px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 14px;
    border-radius: 8px;
}
.monSite-whatsapp_button{
    align-items: center;
    align-self: flex-end;
    background-color: rgb(255, 255, 255);
    border-radius: 50%;
    box-shadow: rgb(0 0 0 / 15%) 0px 3px 12px;
    /* color: rgb(79, 206, 93); */
    cursor: pointer;
    display: flex;
    font-size: 2em;
    height: 64px;
    justify-content: center;
    margin-bottom: 20px;
    margin-right: 20px;
    margin-left: 20px;
    outline: transparent;
    -webkit-user-select: none;
    user-select: none;
    width: 64px;
    -webkit-box-align: center;
    -webkit-box-pack: center;
    position: fixed;
    bottom: 15px;
    right: 30px;
}

.monSite-whatsapp_form{
    width: 360px;
    box-shadow: 0 12px 24px 0 rgb(0 0 0 / 10%);
    -webkit-flex-direction: column;
    flex-direction: column;
    border-radius: 15px;
    overflow: hidden;
    background-color: #fff;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    touch-action: auto;
    display: flex;
    position: absolute;
    margin: 20px;
    padding: 20px;
    bottom: 99px;
    right: 30px;
}
.monSite-popup-content .popup-content{
    position: absolute !important;
    z-index: 999 !important;
    padding: 0px !important;
    border: none !important;
    pointer-events: auto !important;
    top: 138px !important;
    left:auto !important;
    right: 56px !important
}
.StatisticalCard__container{
    margin: 1rem 0 !important;
}
.monSite-container .Card_container{
    width: auto !important;
}
.monSite_loaderAnimation{
    animation:spin 4s linear infinite;
}
@-moz-keyframes spin { 
    100% { -moz-transform: rotate(360deg); } 
}
@-webkit-keyframes spin { 
    100% { -webkit-transform: rotate(360deg); } 
}
@keyframes spin { 
    100% { 
        -webkit-transform: rotate(360deg); 
        transform:rotate(360deg); 
    } 
}

.monSite-notFoundHeader {
    height: 60px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.monSite-notFoundBody {
    /* height: calc(100vh - 60px); */
    /* background-color: rgba(25, 176, 210, .06); */
    padding-top: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.monSite-notFoundBody h3{
    font-weight: 700;
}
.monSite-notFoundBody .errorDesc{
    width: 35%;
    text-align: center;
}

.monSite-notFoundBody .errorSubTitle{
    font-size: 24px;
    text-align: center;
}

.basic-select .basic-select__control {
padding: 0!important;
}

