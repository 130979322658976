.dropdown-item-link{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    text-decoration: none;
    color: #000;
}
.dropdown-item-icon-container{
    width: 52px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center; 
    background-color: rgba(85, 172, 237, 0.08);
    border-radius: 11px;
    margin-right: 0.625rem;
}
.dropdown-item-text{
    font-size: 0.875rem;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
}